@use 'sass:color';

@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');

// Fonts
$bodyfont: 'Poppins', sans-serif;
$headingfont: 'Poppins', sans-serif;

// Theme Colors (overwrite these in a theme file like themes/dark.scss)
// stylelint-disable color-no-hex
$themecolor: #000138;
$themecolor-dark: color.scale($themecolor, $lightness: -5%);
$topbar: $themecolor;
$topbar-alt: #fff;
$sidebar: #fff;
$sidebar-white: #e5edef;
$sidebar-alt: #f2f6f8;
$bodycolor: #eef5f9;
$headingtext: #455a64;
$bodytext: #67757c;
$sidebar-text: #607d8b;
$sidebar-icons: #99abb4;

// Light colors
$light-text: #a6b7bf;
$light-danger: #f9e7eb;
$light-success: #e8fdeb;
$light-warning: #fff8ec;
$light-primary: #f1effd;
$light-info: #cfecfe;
$light-inverse: #f6f6f6;
$light-megna: #e0f2f4;
$danger-dark: #e6294b;
$success-dark: #1eacbe;
$warning-dark: #e9ab2e;
$primary-dark: $themecolor-dark;
$info-dark: #028ee1;
$red-dark: #d61f1f;
$inverse-dark: #232a37;
$dark-transparent: rgba(0, 0, 0, 5%);

// Shadows
$info-shadow: 0 2px 2px 0 rgba(66, 165, 245, 14%), 0 3px 1px -2px rgba(66, 165, 245, 20%), 0 1px 5px 0 rgba(66, 165, 245, 12%);
$warning-shadow: 0 2px 2px 0 rgba(248, 194, 0, 14%), 0 3px 1px -2px rgba(248, 194, 0, 20%), 0 1px 5px 0 rgba(248, 194, 0, 12%);
$danger-shadow: 0 2px 2px 0 rgba(239, 83, 80, 14%), 0 3px 1px -2px rgba(239, 83, 80, 20%), 0 1px 5px 0 rgba(239, 83, 80, 12%);
$success-shadow: 0 2px 2px 0 rgba(40, 190, 189, 14%), 0 3px 1px -2px rgba(40, 190, 189, 20%), 0 1px 5px 0 rgba(40, 190, 189, 12%);
$primary-shadow: 0 2px 2px 0 rgba(116, 96, 238, 14%), 0 3px 1px -2px rgba(116, 96, 238, 20%), 0 1px 5px 0 rgba(116, 96, 238, 12%);
$default-shadow: 0 2px 2px 0 rgba(169, 169, 169, 14%), 0 3px 1px -2px rgba(169, 169, 169, 20%), 0 1px 5px 0 rgba(169, 169, 169, 12%);

// Normal colors
$white: #fff;
$black: #000;
$red: #fb3a3a;
$yellow: #a0aec4;
$purple: #7460ee;
$blue: #02bec9;
$megna: #00897b;
// The following names were generated with http://chir.ag/projects/name-that-color
$trout: #495057;
$alto: #ddd;
$alto-2: #dedede;
$science: #0275d8;
$nevada: #636c72;
$mine-shaft: #2b2b2b;
$gallery: #eee;
$gallery-2: #f0f0f0;
$dusty: #999;
$shuttle: #526069;
$outer-space: #2a3035;
$cerulean: #00aeef;
$ebony-clay: #242933;
$aqua-haze: #f2f7f8;
$wild-sand: #f5f5f5;
$gray: #888;
$ghost: #ced4da;

// Social colors
$facebook: #3b5998;
$twitter: #55acee;
$linkedin: #007bb6;
$dribbble: #ea4c89;
$google: #f86c6b;
$instagram: #3f729b;
$pinterest: #cb2027;
$dropbox: #007ee5;
$flickr: #ff0084;
$tumblr: #32506d;
$skype: #00aff0;
$youtube: #b00;
$github: #171515;

// Extra colors
$border: rgba(120, 130, 140, 13%);
$table-border: #f3f1f1;
$card-brd: #d7dfe3;
$dark-text: #848a96;
$form-brd: #b1b8bb;
// stylelint-enable color-no-hex

// Extra variables
$radius: 4px;
