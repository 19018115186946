/* Center Horizontally */
@mixin center-x($position, $distance) {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  @if $position == 'top' {
    top: $distance;
    bottom: auto;
  } @else {
    top: auto;
    bottom: $distance;
  }
}

/* Center Vertically */
@mixin center-y($position, $distance) {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  @if $position == 'left' {
    left: $distance;
    right: auto;
  } @else {
    left: auto;
    right: $distance;
  }
}

/* Center both Horizontally and Vertically */
@mixin center-x-y {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Fill both Width and Height */
@mixin fill-w-h {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

/* Overflow */
@mixin overflow($axis) {
  -webkit-overflow-scrolling: touch;

  @if $axis == 'x' {
    overflow-x: auto;
  } @else {
    overflow-y: auto;
  }
}

/* Content animation */
@mixin animation-content {
  animation: {
    name: content;
    direction: normal;
    duration: .3s;
    timing-function: ease-in-out;
    iteration-count: 1;
  }

  @keyframes content {
    from {
      opacity: 0;
      transform: translateY(5%);
    }

    to {
      opacity: 1;
      transform: translateY(0%);
    }
  }
}

/* Hover check */
@mixin media-hover($boolean) {
  $hover: null;
  $pointer: null;

  @if $boolean == true {
    $hover: hover;
    $pointer: fine;
  } @else {
    $hover: none;
    $pointer: coarse;
  }

  @media (hover: $hover) and (pointer: $pointer) {
    @content;
  }
}
