// Top bar
.topbar {
  background: $topbar;

  .navbar-header {
    background: $topbar-alt;
  }

  .top-navbar .navbar-header .navbar-brand {
    .light-logo {
      display: none;
      color: $topbar;
    }
  }

  .navbar-light .navbar-nav .nav-item > {
    .nav-link {
      color: $white !important;

      &:hover {
        color: rgba(255, 255, 255, 80%) !important;
      }
    }
  }
}

// General Elements
.link {
  &:hover,
  &:focus {
    color: $themecolor !important;
  }
}

.bg-theme {
  background-color: $themecolor !important;
}

.right-sidebar {
  .rpanel-title {
    background: $themecolor;
  }
}

.stylish-table {
  tbody tr {
    &:hover,
    &.active {
      border-inline-start: 4px solid $themecolor;
    }
  }
}

.text-themecolor {
  color: $themecolor !important;
}

.profile-tab,
.customtab {
  li {
    .nav-link {
      &:hover {
        color: $themecolor;
      }

      &.active {
        border-block-end: 2px solid $themecolor;
        color: $themecolor;
      }
    }
  }
}

// Buttons
.btn-themecolor,
.btn-themecolor.disabled {
  border: 1px solid $themecolor;
  background: $themecolor;
  color: $white;

  &:hover {
    opacity: .7;
    border: 1px solid $themecolor;
    background: $themecolor;
    color: $white;
  }

  &:active,
  &:focus {
    background: $themecolor-dark;
  }
}

// Sidebar navigation
.label-themecolor {
  background: $themecolor;
}

.sidebar-nav > ul > li.active > a {
  border-color: $themecolor;
  color: $themecolor;

  i {
    color: $themecolor;
  }
}

.sidebar-nav ul li a {
  &.active,
  &:hover {
    color: $themecolor;

    i {
      color: $themecolor;
    }
  }
}

// Tables
tr.total {
  font-weight: 700;
}
