@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700");
.morris-hover {
  position: absolute;
  z-index: 1000;
}

.morris-hover.morris-default-style {
  border-radius: 10px;
  padding: 6px;
  color: #666;
  background: rgba(255, 255, 255, 0.8);
  border: solid 2px rgba(230, 230, 230, 0.8);
  font-family: sans-serif;
  font-size: 12px;
  text-align: center;
}

.morris-hover.morris-default-style .morris-hover-row-label {
  font-weight: bold;
  margin: 0.25em 0;
}

.morris-hover.morris-default-style .morris-hover-point {
  white-space: nowrap;
  margin: 0.1em 0;
}

.bg-dark-navy {
  background-color: #020454;
}

.color-dark-navy {
  color: #020454;
}

.bg-midnight-black {
  background-color: #000138;
}

.color-midnight-black {
  color: #000138;
}

.bg-bright-blurple {
  background-color: #6475ed;
}

.color-bright-blurple {
  color: #6475ed;
}

.bg-medium-purple {
  background-color: #8794f2;
}

.color-medium-purple {
  color: #8794f2;
}

.bg-sand {
  background-color: #f6f3ed;
}

.color-sand {
  color: #f6f3ed;
}

.bg-off-white {
  background-color: #fbf9f5;
}

.color-off-white {
  color: #fbf9f5;
}

.bg-steel-grey {
  background-color: #d2d6e2;
}

.color-steel-grey {
  color: #d2d6e2;
}

.bg-border-grey {
  background-color: #e5e5ea;
}

.color-border-grey {
  color: #e5e5ea;
}

.bg-silver-grey {
  background-color: #f3f4f9;
}

.color-silver-grey {
  color: #f3f4f9;
}

.bg-salary-green {
  background-color: #e7f8f3;
}

.color-salary-green {
  color: #5cccb0;
}

.bg-costly-pink {
  background-color: #f7f1fe;
}

.color-costly-pink {
  color: #c89ff8;
}

.bg-profit-blue {
  background-color: #ecf1ff;
}

.color-profit-blue {
  color: #7da4fa;
}

.bg-taxes-blue {
  background-color: #f0edff;
}

.color-taxes-blue {
  color: #5759b1;
}

.bg-blurple {
  background-color: #e8eafd;
}

.color-blurple {
  color: #6475ed;
}

:root {
  --time-fast: .125s;
  --time-norm: .25s;
  --time-slow: .375s;
  --time-slug: .5s;
  --cubic-bezier: cubic-bezier(.05, .69, .14, 1);
}

/* Center Horizontally */
/* Center Vertically */
/* Center both Horizontally and Vertically */
/* Fill both Width and Height */
/* Overflow */
/* Content animation */
/* Hover check */
body.success {
  background-color: clr(emerald);
}
body.failure {
  background-color: clr(froly);
}

.topbar {
  background: #000138;
}
.topbar .navbar-header {
  background: #fff;
}
.topbar .top-navbar .navbar-header .navbar-brand .light-logo {
  display: none;
  color: #000138;
}
.topbar .navbar-light .navbar-nav .nav-item > .nav-link {
  color: #fff !important;
}
.topbar .navbar-light .navbar-nav .nav-item > .nav-link:hover {
  color: rgba(255, 255, 255, 0.8) !important;
}

.link:hover, .link:focus {
  color: #000138 !important;
}

.bg-theme {
  background-color: #000138 !important;
}

.right-sidebar .rpanel-title {
  background: #000138;
}

.stylish-table tbody tr:hover, .stylish-table tbody tr.active {
  border-inline-start: 4px solid #000138;
}

.text-themecolor {
  color: #000138 !important;
}

.profile-tab li .nav-link:hover,
.customtab li .nav-link:hover {
  color: #000138;
}
.profile-tab li .nav-link.active,
.customtab li .nav-link.active {
  border-block-end: 2px solid #000138;
  color: #000138;
}

.btn-themecolor,
.btn-themecolor.disabled {
  border: 1px solid #000138;
  background: #000138;
  color: #fff;
}
.btn-themecolor:hover,
.btn-themecolor.disabled:hover {
  opacity: 0.7;
  border: 1px solid #000138;
  background: #000138;
  color: #fff;
}
.btn-themecolor:active, .btn-themecolor:focus,
.btn-themecolor.disabled:active,
.btn-themecolor.disabled:focus {
  background: #000135;
}

.label-themecolor {
  background: #000138;
}

.sidebar-nav > ul > li.active > a {
  border-color: #000138;
  color: #000138;
}
.sidebar-nav > ul > li.active > a i {
  color: #000138;
}

.sidebar-nav ul li a.active, .sidebar-nav ul li a:hover {
  color: #000138;
}
.sidebar-nav ul li a.active i, .sidebar-nav ul li a:hover i {
  color: #000138;
}

tr.total {
  font-weight: 700;
}

.login-box .card {
  background-color: #020454;
  color: #fff;
}
.login-box .card h1,
.login-box .card h2,
.login-box .card h3,
.login-box .card h4,
.login-box .card h5,
.login-box .card h6 {
  color: #fff;
}
.login-box .card input {
  background-color: #020454 !important;
}
.login-box .card .reset-password {
  color: #020454;
}
@media (hover: hover) and (pointer: fine) {
  .login-box .card .reset-password {
    transition: color var(--time-norm) ease-out;
  }
  .login-box .card .reset-password:hover {
    color: #fff;
  }
}

.login-box .card .form-material .form-control,
.login-box .card .form-material .form-control.focus,
.login-box .card .form-material .form-control:focus {
  border-block-end: 1px solid #fff;
  background-image: none;
  color: #fff;
}
.login-box .card .form-material .form-control::placeholder,
.login-box .card .form-material .form-control.focus::placeholder,
.login-box .card .form-material .form-control:focus::placeholder {
  color: #f3f4f9;
}

body.login [type=checkbox]:not(:checked) + label::before,
body.login [type=checkbox]:not(:checked):not(.filled-in) + label::after {
  border-color: #fff;
}