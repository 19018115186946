@use 'sass:color';

.form-material .form-control,
.form-material .form-control.focus,
.form-material .form-control:focus {
  .login-box .card & {
    border-block-end: 1px solid $white;
    background-image: none;
    color: $white;

    &::placeholder {
      color: $silver-grey;
    }
  }
}
