.login-box {
  .card {
    background-color: $dark-navy;
    color: $white;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $white;
    }

    input {
      background-color: $dark-navy !important;
    }

    .reset-password {
      color: $dark-navy;

      @include media-hover(true) {
        transition: color var(--time-norm) ease-out;

        &:hover {
          color: $white;
        }
      }
    }
  }
}
