$white: #fff;
$dark-navy: #020454;
$midnight-black: #000138;
$bright-blurple: #6475ed;
$medium-purple: #8794f2;
$sand: #f6f3ed;
$off-white: #fbf9f5;
$steel-grey: #d2d6e2;
$border-grey: #e5e5ea;
$silver-grey: #f3f4f9;
$salary-green: #5cccb0;
$light-salary-green: #e7f8f3;
$costly-pink: #c89ff8;
$light-costly-pink: #f7f1fe;
$profit-blue: #7da4fa;
$light-profit-blue: #ecf1ff;
$taxes-blue: #5759b1;
$light-taxes-blue: #f0edff;
$light-blurple: #e8eafd;
$validation-green: #44c68f;
$validation-green-15: #d9f4e9;
$validation-red: #ea4c77;
$validation-red-15: #fcecf0;
$validation-orange: #fcba6c;
$validation-orange-15: #fff5e9;
$colors: (
  primary: (('dark-navy', $dark-navy, $dark-navy), ('midnight-black', $midnight-black, $midnight-black), ('bright-blurple', $bright-blurple, $bright-blurple), ('medium-purple', $medium-purple, $medium-purple), ('sand', $sand, $sand), ('off-white', $off-white, $off-white)),
  secondary: (('steel-grey', $steel-grey, $steel-grey), ('border-grey', $border-grey, $border-grey), ('silver-grey', $silver-grey, $silver-grey)),
  tertiary: (('salary-green', $salary-green, $light-salary-green), ('costly-pink', $costly-pink, $light-costly-pink), ('profit-blue', $profit-blue, $light-profit-blue), ('taxes-blue', $taxes-blue, $light-taxes-blue), ('blurple', $bright-blurple, $light-blurple))
);

@each $color-group, $color-definition in $colors {
  @each $color-name, $color-code, $color-light in $color-definition {
    .bg-#{$color-name} {
      background-color: $color-light;
    }
    .color-#{$color-name} {
      color: $color-code;
    }
  }
}
